function uuid() {
	const str = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c)=> {
		const r = (Math.random() * 16) | 0;
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
  return str + '-' + new Date().getTime()
}

export default function getToken() {
  const token = sessionStorage.getItem('token')
  console.log(`output-> token`, token)
  if (token) {
    return token
  }
  const newToken = uuid()
  sessionStorage.setItem('token', newToken);
  return newToken
}